import React, {useContext, useEffect, useState} from 'react';
import Map from "../Map/Map";
import TendersSideInfo from "./TendersSideInfo";
import TendersFilter from "./TendersFilter";
import {getTendersDistrictInfo} from "../../services/tenders";
import {AppDataContext} from "../../contexts/AppDataContext";
import dayjs from "dayjs";
import {ITenderDistrictInfo} from "../../types/tenders";
import {TendersContext} from "../../contexts/TendersContext";
import {getDistrictData} from "../../utils";

const TendersRegionPage = () => {
    const {date, updateDistrictsData} = useContext(AppDataContext);
    const {
        customer,
        supplier,
        subject,
        status,
        budgetType,
        type,
        tradeMethod,
    } = useContext(TendersContext);
    const [tenderInfo, setTenderInfo] = useState<ITenderDistrictInfo[]>([]);

    useEffect(() => {
        loadTenderInfo();
    }, [date, customer, supplier, subject, status, budgetType, type, tradeMethod])

    const loadTenderInfo = async (): Promise<void> => {
        const response = await getTendersDistrictInfo(
            dayjs(date).format("YYYY-MM-DD"), status.selectedIds, budgetType.selectedIds, customer.selectedIds, subject.ids, supplier.selectedIds, tradeMethod.selectedIds, type.ids
        );

        if (!response.isError) {
            setTenderInfo(response.data);
            updateDistrictsData(getDistrictData(response.data));
        } else {
            setTenderInfo([]);
        }
    };

    return (
        <>
            <Map tenderInfo={tenderInfo} />
            <TendersSideInfo />
            <TendersFilter />
        </>
    );
};

export default TendersRegionPage;