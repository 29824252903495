import React, { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Arrow } from "../Icons";
import { getDistrictNameById } from "../../utils";
import ProvisionOfTheRegion from "./ProvisionOfTheRegion";
import PopulationCommunicationsStatuses from "./PopulationCommunicationsStatuses";
import ConstructionOfAntennas from "./ConstructionOfAntennas";

const InternetSideInfo: React.FC = () => {
    const params = useParams();
    const districtId = useMemo(() => params.id, [params]);
    const navigate = useNavigate();

    return (
        <div className="side-info side-info-scroll">
            {districtId && (
                <div
                    className="side-info-district"
                    id="side-info-district-name"
                >
                    <div
                        className="side-info-card back-btn floods-back-btn"
                        onClick={() => navigate("/internet")}
                    >
                        <Arrow />
                    </div>
                    <div className="side-info-card side-info-district-name">
                        {getDistrictNameById(Number(districtId))}
                    </div>
                </div>
            )}

            <ProvisionOfTheRegion />
            <PopulationCommunicationsStatuses />

            <button
                type="button"
                className="button"
            >
                Подробнее
            </button>

            <ConstructionOfAntennas />
        </div>
    );
};

export default InternetSideInfo;
