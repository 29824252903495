import React, {useContext, useState} from 'react';
import {ChevronDown} from "../Icons";
import {useNavigate} from "react-router-dom";
import {AppDataContext} from "../../contexts/AppDataContext";

const DashboardSwitcher = () => {
    const navigate = useNavigate();
    const {updateDate, updateDateRange} = useContext(AppDataContext);
    const [openCategory, setOpenCategory] = useState<string | null>(null);
    const categories =
        process.env.NODE_ENV === "development"
            ?
                [
                    {name: "ЧС", options: ["Паводки", "Пожары"]},
                    {name: "ЖКХ", options: ["Выработка электроэнергии", "Задолжности", "Водоснабжение"]},
                    {name: "УГЗ", options: ["Закупки"]},
                    {name: "Цифровизация", options: ["Обеспеченность ШПД"]}
                ]
            :
                [
                    {name: "ЧС", options: ["Паводки", "Пожары"]},
                    {name: "ЖКХ", options: ["Задолжности"]},
                    {name: "УГЗ", options: ["Закупки"]},
                ];

    const toggleCategory = (categoryName: string) => {
        if (openCategory === categoryName) {
            setOpenCategory(null);
        } else {
            setOpenCategory(categoryName);
        }
    };

    const handleOptionChange = (option: string, category: string) => {
        toggleCategory(category);
        handlePageChange();
        if (option === "Паводки") navigate("/");
        if (option === "Пожары") navigate("/fires");
        if (option === "Выработка электроэнергии") navigate("/power-generation");
        if (option === "Задолжности") navigate("/debts");
        if (option === "Закупки") navigate("/tenders");
        if (option === "Водоснабжение") navigate("/water-supply");
        if (option === "Обеспеченность ШПД") navigate("/internet");
    };

    const handleCategoryClick = (category: string) => {
        handlePageChange();
    };

    const handlePageChange = () => {
        localStorage.removeItem("date");
        localStorage.removeItem("dateTo");
        localStorage.removeItem("dateFrom");
        updateDate(new Date());
        const yesterday = new Date();
        yesterday.setDate(new Date().getDate() - 7);
        updateDateRange({from: yesterday, to: new Date()})
    };

    return (
        <div className="dashboard-switcher-wrapper">
            {categories.map((category) =>
                <div key={category.name}>
                    <div
                        onClick={() => {
                            if (category.options?.length) {
                                toggleCategory(category.name);
                            } else {
                                handleCategoryClick(category.name);
                            }
                        }}
                        className={category.options?.length ? "dashboard-switcher" : "dashboard-switcher single"}
                    >
                        {category.name}
                        {category.options?.length &&
                            <ChevronDown color="black" className={openCategory === category.name ? " close-arrow" : "open-arrow"} />
                        }
                    </div>
                    {openCategory === category.name && (
                        <div className="dashboard-switcher-list-wrapper">
                            {category.options?.length && category.options.map((option) => (
                                <div
                                    key={option}
                                    className="dashboard-switcher dashboard-switcher-list"
                                    onClick={() => handleOptionChange(option, category.name)}
                                >
                                    {option}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default DashboardSwitcher;