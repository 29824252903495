import React, {useContext, useEffect, useState} from 'react';
import DatePicker from "../UI/DatePicker";
import {useLocation, useParams} from "react-router-dom";
import {AppDataContext} from "../../contexts/AppDataContext";
import YearPicker from "../UI/YearPicker";
import PresentationButton from '../PresentationButton/PresentationButton';
import { InfoIcon } from '../Icons';
import PassportModal from '../PassportModal/PassportModal';
import FloodsExportButton from '../FloodsPages/FloodsExportButton';

const Header = () => {
    const {updateDate} = useContext(AppDataContext);
    const location = useLocation();
    const [datePickerRange, setDatePickerRange] = useState<boolean>(false);
    const [yearPicker, setYearPicker] = useState<boolean>(false);
    const [infoModalOpen, setInfoModalOpen] = useState(false);
    const params = useParams();

    useEffect(() => {
        if (location.pathname.includes("/power-generation") || location.pathname.includes("/debts")) {
            setDatePickerRange(true);
        } else {
            setDatePickerRange(false);
        }

        if (location.pathname === "/tenders") {
            if (!localStorage.getItem("date")) {
                const yesterday = new Date();
                yesterday.setDate(new Date().getDate() - 7);
                updateDate(yesterday);
            }
        }

        if (location.pathname.includes("water-supply")) {
            setYearPicker(true);
        } else {
            setYearPicker(false);
        }
    }, [location]);

    const getHeaderText = () => {
        if (location.pathname.includes("/fires")) return "Ситуация по пожарам";
        if (location.pathname.includes("/power-generation")) return "Выработка и потребление электроэнергии";
        if (location.pathname.includes("/debts")) return "Задолженность за электроэнергию";
        if (location.pathname.includes("/tenders")) return "Анализ осуществления государственных закупок";
        if (location.pathname.includes("/water-supply")) return "Обеспеченность водоснабжением";
        if (location.pathname.includes("/internet")) return "Анализ обеспеченности ШПД в регионе";
        return "Ситуация по паводками";
    };

    return (
        <>
            <div className="header">
                <div className="header-wrapper">
                    <div className="header-item">
                        <div className="header-title">{getHeaderText()}</div>

                        <button className="header-info-button" type="button" onClick={() => setInfoModalOpen(true)}>
                            <InfoIcon />
                        </button>
                    </div>
                    <div className="header-actions">
                        {(location.pathname === "/" || location.pathname === `/district/${params.id}`) &&
                            <FloodsExportButton />
                        }

                        <PresentationButton />

                        {yearPicker ?
                            <YearPicker />
                        :
                            <DatePicker range={datePickerRange}/>
                        }
                    </div>
                </div>
            </div>

            {
                infoModalOpen &&
                
                <PassportModal
                    isOpen={infoModalOpen}
                    onClose={() => setInfoModalOpen(false)}
                />
            }
        </>
    );
};

export default Header;