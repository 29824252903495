import React, {useContext, useEffect, useState} from 'react';
import {ChevronOutline} from "../Icons";
import BarChart from "../Charts/BarChart";
import {AppDataContext} from "../../contexts/AppDataContext";
import {IMethod, IStatus, IStatusSummary} from "../../types/tenders";
import {getTendersStatusSummary} from "../../services/tenders";
import dayjs from "dayjs";
import {chartColors} from "../../utils/options";
import {useParams} from "react-router-dom";
import {TendersContext} from "../../contexts/TendersContext";
import {addSpaces} from "../../utils";

const TendersPurchaseCard = () => {
    const params = useParams();
    const districtId = params.id;
    const {date} = useContext(AppDataContext);
    const {
        customer,
        supplier,
        subject,
        status,
        budgetType,
        type,
        tradeMethod,
    } = useContext(TendersContext);
    const [slide, setSlide] = useState<number>(0);
    const [statuses, setStatuses] = useState<IStatus[]>([]);
    const [methods, setMethods] = useState<IMethod[]>([]);
    const [statusesCount, setStatusesCount] = useState<number>(0);
    const [methodCount, setMethodCount] = useState<number>(0);
    const titles = ["Способ закупки", "Статус закупки"];

    useEffect(() => {
        loadStatuses();
    }, [date, customer, supplier, subject, status, budgetType, type, tradeMethod, districtId]);

    const loadStatuses = async (): Promise<void> => {
        const response = await getTendersStatusSummary(
            dayjs(date).format("YYYY-MM-DD"), districtId, status.selectedIds, budgetType.selectedIds, customer.selectedIds, subject.ids, supplier.selectedIds, tradeMethod.selectedIds, type.ids
        );

        if (!response.isError) {
            setStatuses(response.data.status);
            setMethods(response.data.method);
            getStatisticCount(response.data);
        } else {
            setStatuses([]);
        }
    };

    const getStatisticCount = (data: IStatusSummary) => {
        let totalStatus: number = 0;
        let totalMethod: number = 0;

        data.status.forEach(item => {
            totalStatus += item.count;
        });

        data.method.forEach(item => {
            totalMethod += item.count;
        });

        setStatusesCount(totalStatus);
        setMethodCount(totalMethod);
    };

    const getChartData = (data: IStatus[] | IMethod[]) => {
        let names: string[] = [];
        let statusesData: number[] = [];
        let statusesColors: string[] = [];

        data.forEach((item, index) => {
            if (item.count) {
                names.push(item.label);
                statusesData.push(item.count);
                statusesColors.push(chartColors[index]);
            }
        });

        return {
            labels: names,
            datasets: [
                {
                    data: statusesData,
                    backgroundColor: statusesColors,
                    borderColor: statusesColors,
                    borderWidth: 1
                }
            ]
        }
    };

    return (
        <div className="side-info-big-card">
            <div className="side-info-big-card-slider">
                <span className="side-info-big-card-slider-arrow prev" onClick={() => setSlide(slide === 0 ? 1 : 0)}><ChevronOutline /></span>
                <span className="side-info-big-card-title">{titles[slide]}</span>
                <span className="side-info-big-card-slider-arrow" onClick={() => setSlide(slide === 0 ? 1 : 0)}><ChevronOutline /></span>
            </div>

            {slide === 0 &&
                <div>
                    <div style={{marginBottom: "20px"}}>
                        <BarChart
                            data={getChartData(methods)}
                            isLegend={false}
                            responsive
                            chartHeight={100}
                            onlyGraph
                        />
                    </div>

                    <div className="side-info-big-card-items">
                        <div className="side-info-big-card-item side-info-big-card-item-title">
                            <div>ИТОГО</div>
                            <div className="side-info-big-card-value">{addSpaces(String(methodCount))} шт</div>
                        </div>
                        {methods.map((method, index) =>
                            method.count ?
                                <div className="side-info-big-card-item" key={index}>
                                    <div className="side-info-big-card-item-text">
                                        <div className="dot" style={{backgroundColor: chartColors[index]}} />
                                        <p>{method.label}</p>
                                    </div>
                                    <div className="side-info-big-card-item-value">{addSpaces(String(method.count))} шт</div>
                                </div>
                            : null
                        )}
                    </div>
                </div>
            }

            {slide === 1 &&
                <div>
                    <div style={{marginBottom: "20px"}}>
                        <BarChart
                            data={getChartData(statuses)}
                            isLegend={false}
                            responsive
                            chartHeight={100}
                            onlyGraph
                        />
                    </div>

                    <div className="side-info-big-card-items">
                        <div className="side-info-big-card-item side-info-big-card-item-title">
                            <div>ИТОГО</div>
                            <div className="side-info-big-card-value">{addSpaces(String(statusesCount)) + " шт"}</div>
                        </div>
                        {statuses.map((status, index) =>
                            status.count ?
                                <div className="side-info-big-card-item" key={index}>
                                    <div className="side-info-big-card-item-text">
                                        <div className="dot" style={{backgroundColor: chartColors[index]}} />
                                        <p>{status.label}</p>
                                    </div>
                                    <div className="side-info-big-card-item-value">{addSpaces(String(status.count))} шт</div>
                                </div>
                            : null
                        )}
                    </div>
                </div>
            }
        </div>
    );
};

export default TendersPurchaseCard;