import React, {useContext, useEffect, useState} from 'react';
import {TendersContext} from "../../contexts/TendersContext";
import Map from "../Map/Map";
import TendersSideInfo from "./TendersSideInfo";
import TendersFilter from "./TendersFilter";
import {AppDataContext} from "../../contexts/AppDataContext";
import {ITenderDistrictInfo} from "../../types/tenders";
import {getTendersCountyInfo, getTendersDistrictInfo} from "../../services/tenders";
import dayjs from "dayjs";
import {useParams} from "react-router-dom";
import {getDistrictData} from "../../utils";

const TendersDistrictPage = () => {
    const params = useParams();
    const districtId = params.id;
    const {date, updateDistrictsData} = useContext(AppDataContext);
    const {
        customer,
        supplier,
        subject,
        status,
        budgetType,
        type,
        tradeMethod,
    } = useContext(TendersContext);
    const [tenderCountyInfo, setTenderCountyInfo] = useState<ITenderDistrictInfo[]>([]);

    useEffect(() => {
        loadTenderCountiesInfo();
    }, [date, customer, supplier, subject, status, budgetType, type, tradeMethod, districtId]);

    useEffect(() => {
        loadTenderInfo();
    }, []);

    const loadTenderCountiesInfo = async (): Promise<void> => {
        if (districtId) {
            const response = await getTendersCountyInfo(
                dayjs(date).format("YYYY-MM-DD"), districtId, status.selectedIds, budgetType.selectedIds, customer.selectedIds, subject.ids, supplier.selectedIds, tradeMethod.selectedIds, type.ids
            );

            if (!response.isError) {
                setTenderCountyInfo(response.data);
            } else {
                setTenderCountyInfo([]);
            }
        }
    };

    const loadTenderInfo = async (): Promise<void> => {
        const response = await getTendersDistrictInfo(
            dayjs(date).format("YYYY-MM-DD"), status.selectedIds, budgetType.selectedIds, customer.selectedIds, subject.ids, supplier.selectedIds, tradeMethod.selectedIds, type.ids
        );

        if (!response.isError) {
            updateDistrictsData(getDistrictData(response.data));
        }
    };

    return (
        <>
            <Map districtId={Number(districtId)} tenderInfo={tenderCountyInfo} regionOnly />
            <TendersSideInfo />
            <TendersFilter />
        </>
    );
};

export default TendersDistrictPage;