import axiosInstance from "./axiosInstance";

type TResult<DataType> =
    | {
    isError: true;
    error: string;
}
    | {
    isError: false;
    data: DataType;
};

export const login = async (password: string, userName: string): Promise<TResult<{auth_token: string}>> => {
    try {
        const response = await axiosInstance.post('v1/auth/token/login/', {
            password: password,
            username: userName
        });

        return { isError: false, data: response.data };
    } catch (error: any) {
        return { isError: true, error: error.response?.data || error.message };
    }
};