import React, {useContext, useEffect, useState} from 'react';
import {ChevronOutline} from "../Icons";
import DonutChart from "../Charts/DonutChart";
import {getAnnounceSummary} from "../../services/tenders";
import dayjs from "dayjs";
import {AppDataContext} from "../../contexts/AppDataContext";
import {IAnnounceSummary} from "../../types/tenders";
import {addSpaces, getSumString} from "../../utils";
import {useParams} from "react-router-dom";
import {TendersContext} from "../../contexts/TendersContext";

const TendersAnnouncementCard = () => {
    const params = useParams();
    const districtId = params.id;
    const {date} = useContext(AppDataContext);
    const {
        customer,
        supplier,
        subject,
        status,
        budgetType,
        type,
        tradeMethod,
    } = useContext(TendersContext);
    const [page, setPage] = useState<number>(0);
    const [announceData, setAnnounceData] = useState<IAnnounceSummary>();
    const titles = ["Объявления по сумме", "Объявления по статусу"];

    useEffect(() => {
        loadAnnounceSummary();
    }, [date, customer, supplier, subject, status, budgetType, type, tradeMethod, districtId]);

    const loadAnnounceSummary = async (): Promise<void> => {
        const response = await getAnnounceSummary(
            dayjs(date).format("YYYY-MM-DD"), districtId, status.selectedIds, budgetType.selectedIds, customer.selectedIds, subject.ids, supplier.selectedIds, tradeMethod.selectedIds, type.ids
        );

        if (!response.isError) {
            setAnnounceData(response.data);
        }
    };

    return (
        <div className="side-info-big-card">
            {announceData &&
                <>
                    <div className="side-info-big-card-slider">
                        <span className="side-info-big-card-slider-arrow prev" onClick={() => setPage(page === 0 ? 1 : 0)}><ChevronOutline /></span>
                        <span className="side-info-big-card-title">{titles[page]}</span>
                        <span className="side-info-big-card-slider-arrow" onClick={() => setPage(page === 0 ? 1 : 0)}><ChevronOutline /></span>
                    </div>

                    {page === 0 &&
                        <div className="donut-statistic">
                            <div className="side-info-big-card-items">
                                <div className="side-info-big-card-item side-info-big-card-item-title">
                                    <div>ВСЕГО СУММА ЗАКУПОК</div>
                                    <div className="side-info-big-card-item-value">{addSpaces(getSumString(announceData.all_sum))}</div>
                                </div>
                                <div className="side-info-big-card-item">
                                    <div className="side-info-big-card-item-text">
                                        <div className="dot" style={{backgroundColor: "#00D67E"}} />
                                        <p>Проведено (сумма по состоявшимся закупкам)</p>
                                    </div>
                                    <div className="side-info-big-card-item-value">{getSumString(announceData.data.total_sum)}</div>
                                </div>
                                <div className="side-info-big-card-item">
                                    <div className="side-info-big-card-item-text">
                                        <div className="dot" style={{backgroundColor: "#FFB933"}} />
                                        <p>Плановые закупки (сумма еще не состоявшихся)</p>
                                    </div>
                                    <div className="side-info-big-card-item-value">{getSumString(announceData.data.announcement_total_sum)}</div>
                                </div>
                                <div className="side-info-big-card-item">
                                    <div className="side-info-big-card-item-text">
                                        <div className="dot" style={{backgroundColor: "#3880CE"}} />
                                        <p>Экономия</p>
                                    </div>
                                    <div className="side-info-big-card-item-value">{getSumString(announceData.data.total_sum - announceData.data.announcement_total_sum)}</div>
                                </div>
                            </div>

                            <div className="donut-statistic-chart">
                                <DonutChart
                                    labels={["Проведено", "Плановые закупки", "Экономия"]}
                                    values={[announceData.data.total_sum, announceData.data.announcement_total_sum, announceData.data.total_sum - announceData.data.announcement_total_sum]}
                                    backgroundColor={["#00D67E", "#FFB933", "#3880CE"]}
                                    thickness="85%"
                                    borderRadius={50}
                                    distanceBetweenSegments={7}
                                    rotation={150}
                                    customTooltip={{top: "185", left: "290"}}
                                />
                                <span className="percent">%</span>
                            </div>
                        </div>
                    }

                    {page === 1 &&
                        <div className="donut-statistic">
                            <div className="side-info-big-card-items">
                                <div className="side-info-big-card-item side-info-big-card-item-title">
                                    <div>ВСЕГО ЗАКУПОК</div>
                                    <div className="side-info-big-card-item-value">{addSpaces(String(announceData.data.completed + announceData.data.processing))} шт</div>
                                </div>
                                <div className="side-info-big-card-item">
                                    <div className="side-info-big-card-item-text">
                                        <div className="dot" style={{backgroundColor: "#00D67E"}} />
                                        <p>Проведено кол-во</p>
                                    </div>
                                    <div className="side-info-big-card-item-value">{addSpaces(String(announceData.data.completed))} шт</div>
                                </div>
                                <div className="side-info-big-card-item">
                                    <div className="side-info-big-card-item-text">
                                        <div className="dot" style={{backgroundColor: "#FFB933"}} />
                                        <p>Плановые закупки кол-во</p>
                                    </div>
                                    <div className="side-info-big-card-item-value">{addSpaces(String(announceData.data.processing))} шт</div>
                                </div>
                            </div>

                            <div className="donut-statistic-chart">
                                <DonutChart
                                    labels={["Проведено", "Плановые"]}
                                    values={[announceData.data.completed, announceData.data.processing]}
                                    backgroundColor={["#00D67E", "#FFB933"]}
                                    thickness="85%"
                                    borderRadius={50}
                                    distanceBetweenSegments={7}
                                    rotation={150}
                                    customTooltip={{top: "185", left: "290"}}
                                />
                                <span className="percent">%</span>
                            </div>
                        </div>
                    }
                </>
            }
        </div>
    )
};

export default TendersAnnouncementCard;