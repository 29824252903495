import React from 'react';

interface IButton {
    text: string;
    onClick: (e: React.MouseEvent) => void;
}

const Button: React.FC<IButton> = ({ text, onClick }) => {
    return (
        <button
            className="button"
            onClick={onClick}
        >
            {text}
        </button>
    );
};

export default Button;