import React, {useContext, useEffect, useState} from 'react';
import NewFilter from "../UI/NewFilter";
import {Cancel, ChevronOutline} from "../Icons";
import Search from "../UI/Search";
import {getTendersCustomers, getTendersSuppliers} from "../../services/tenders";
import dayjs from "dayjs";
import {announcementStatus, budgetTypes, tradeMethodFact} from "../../utils/options";
import {AppDataContext} from "../../contexts/AppDataContext";
import {TendersContext} from "../../contexts/TendersContext";
import {Tooltip} from "react-tooltip";

const TendersFilter = () => {
    const {date} = useContext(AppDataContext);
    const {customer, supplier, subject, status, budgetType, type, tradeMethod, setCustomer, setSupplier, setSubject, setStatus, setBudgetType, setType, setTradeMethod} = useContext(TendersContext);
    const [amount, setAmount] = useState<number>(0);
    const [suppliers, setSuppliers] = useState<{id: number, label: string}[]>([]);
    const [customers, setCustomers] = useState<{id: number, label: string}[]>([]);

    useEffect(() => {
        loadSuppliers();
        loadCustomers()
    }, [date]);

    useEffect(() => {
        let count = 0;

        if (tradeMethod.selected && tradeMethod.selected.length !== 0) count += 1;
        if (type.value.some(item => item.checked)) count += 1;
        if (budgetType.selected && budgetType.selected.length !== 0) count += 1;
        if (status.selected && status.selected.length !== 0) count += 1;
        if (subject.value.some(item => item.checked)) count += 1;
        if (supplier.selected && supplier.selected.length !== 0) count += 1;
        if (customer.selected && customer.selected.length !== 0) count += 1;

        setAmount(count);
    }, [tradeMethod, type, budgetType, status, subject, supplier, customer]);

    const loadSuppliers = async (search?: string): Promise<void> => {
        const response = await getTendersSuppliers(dayjs(date).format("YYYY-MM-DD"), search, 1, search ? 100 : 10);

        if (!response.isError) {
            const data = response.data.results.map(item => ({
                id: item.id,
                label: item.name
            }))
            setSuppliers(data);
        } else {
            setSuppliers([]);
        }
    };

    const loadCustomers = async (search?: string): Promise<void> => {
        const response = await getTendersCustomers(dayjs(date).format("YYYY-MM-DD"), search, 1, search ? 100 : 10);

        if (!response.isError) {
            const data = response.data.results.map(item => ({
                id: item.id,
                label: item.name
            }))
            setCustomers(data);
        } else {
            setSuppliers([]);
        }
    };

    const closeAll = () => {
        setTradeMethod({...tradeMethod, open: false});
        setType({...type, open: false});
        setBudgetType({...budgetType, open: false});
        setStatus({...status, open: false});
        setSubject({...subject, open: false});
        setSupplier({...supplier, open: false});
        setCustomer({...customer, open: false});
    };

    const handleCheckboxes = (opt: any, setFunction: (arg: any) => void) => {
        setFunction((prevState: any) => ({
            ...prevState,
            value: prevState.value.map((option: any) =>
                option.label === opt.label ? { ...option, checked: !option.checked } : option
            ),
            ids: prevState.ids.includes(opt.id)
                ? prevState.ids.filter((v: any) => v !== opt.id)
                : [...prevState.ids, opt.id],
        }))
    };

    return (
        <NewFilter amount={amount}>
            <div className="new-filter-options">
                <div className="new-filter-option">
                    {customer.selected.length > 0 &&
                        <div className="new-filter-option-name new-filter-option-name-chosen">
                            <div>
                                <span>Выбрано: </span>
                                <span className="new-filter-option-name-current">{customer.selected.length > 3 ? `${customer.selected.slice(0, 3).join(", ")} и еще(${customer.selected.length - 3})` : customer.selected.join(", ")}</span>
                            </div>
                            <span onClick={() => setCustomer({...customer, selected: [], selectedIds: []})}>
                                <Cancel />
                            </span>
                        </div>
                    }

                    <div className="new-filter-option-name" onClick={() => {
                        closeAll();
                        setCustomer({...customer, open: !customer.open})
                    }}>
                        <span>Заказчик</span>
                        <span className={customer.open ? "arrow-up" : "arrow"}><ChevronOutline /></span>
                    </div>
                </div>

                {customer.open &&
                    <>
                        <Search
                            value={customer.value as string}
                            placeholder="Поиск по заказчикам"
                            onChange={(e) => {
                                loadCustomers(e);
                                setCustomer({...customer, value: e});
                            }}
                            onCancel={() => {
                                loadCustomers();
                                setCustomer({...customer, value: ""});
                            }}
                            withCheckboxes
                            options={customers}
                            selected={customer.selected}
                            setSelected={setCustomer}
                            tooltipId="customer"
                        />
                        <Tooltip id="customer" place="bottom-end" style={{textWrap: "wrap", wordWrap: "break-word", maxWidth: "250px", fontSize: "12px"}}/>
                    </>
                }

                <div className="new-filter-option">
                    {supplier.selected.length > 0 &&
                        <div className="new-filter-option-name new-filter-option-name-chosen">
                            <div>
                                <span>Выбрано: </span>
                                <span className="new-filter-option-name-current">{supplier.selected.length > 3 ? `${supplier.selected.slice(0, 3).join(", ")} и еще(${supplier.selected.length - 3})` : supplier.selected.join(", ")}</span>
                            </div>
                            <span onClick={() => setSupplier({...supplier, selected: [], selectedIds: []})}>
                                <Cancel />
                            </span>
                        </div>
                    }

                    <div className="new-filter-option-name" onClick={() => {
                        closeAll();
                        setSupplier({...supplier, open: !supplier.open})
                    }}>
                        <span>Поставщик</span>
                        <span className={supplier.open ? "arrow-up" : "arrow"}><ChevronOutline /></span>
                    </div>
                </div>

                {supplier.open &&
                    <>
                        <Search
                            value={supplier.value as string}
                            placeholder="Поиск по поставщикам"
                            onChange={(e) => {
                                loadSuppliers(e);
                                setSupplier({...supplier, value: e});
                            }}
                            onCancel={() => {
                                loadSuppliers();
                                setSupplier({...supplier, value: ""});
                            }}
                            withCheckboxes
                            options={suppliers}
                            selected={supplier.selected}
                            setSelected={setSupplier}
                            tooltipId="supplier"
                        />
                        <Tooltip id="supplier" place="bottom-end" style={{textWrap: "wrap", wordWrap: "break-word", maxWidth: "250px", fontSize: "12px"}}/>
                    </>
                }

                <div className="new-filter-option">
                    {subject.value.some(item => item.checked) &&
                        <div className="new-filter-option-name new-filter-option-name-chosen">
                            <div>
                                <span>Выбрано: </span>
                                <span className="new-filter-option-name-current">
                                    {subject.value.filter(item => item.checked)
                                        .map(item => item.label)
                                        .join(', ')
                                    }
                                </span>
                            </div>
                            <span onClick={() => {
                                const updatedValue = subject.value.map(item => ({
                                    ...item,
                                    checked: false,
                                }));

                                setSubject({
                                    ...subject,
                                    value: updatedValue,
                                    ids: [],
                                });
                            }}
                            >
                                <Cancel />
                            </span>
                        </div>
                    }

                    <div className="new-filter-option-name" onClick={() => {
                        closeAll();
                        setSubject({...subject, open: !subject.open})
                    }}>
                        <span>Вид предмета закупок</span>
                        <span className={subject.open ? "arrow-up" : "arrow"}><ChevronOutline /></span>
                    </div>
                </div>

                {subject.open &&
                    <div className="new-filter-option-name-checkbox">
                        {subject.value.map((item) =>
                            <div
                                className="new-filter-option-name-checkbox-item"
                                key={item.label}
                            >
                                <input
                                    className="checkbox"
                                    type="checkbox"
                                    id={item.label}
                                    value={item.label}
                                    checked={item.checked}
                                    onChange={() => {
                                        handleCheckboxes(item, setSubject);
                                    }}
                                />
                                <label className="checkbox-label" htmlFor={item.label}>{item.label}</label>
                            </div>
                        )}
                    </div>
                }

                <div className="new-filter-option">
                    {status.selected.length > 0 &&
                        <div className="new-filter-option-name new-filter-option-name-chosen">
                            <div>
                                <span>Выбрано: </span>
                                <span className="new-filter-option-name-current">{status.selected.length > 3 ? `${status.selected.slice(0, 3).join(", ")} и еще(${status.selected.length - 3})` : status.selected.join(", ")}</span>
                            </div>
                            <span onClick={() => setStatus({...status, selected: [], selectedIds: []})}>
                                <Cancel />
                            </span>
                        </div>
                    }

                    <div className="new-filter-option-name" onClick={() => {
                        closeAll();
                        setStatus({...status, open: !status.open})
                    }}>
                        <span>Статус закупки</span>
                        <span className={status.open ? "arrow-up" : "arrow"}><ChevronOutline /></span>
                    </div>
                </div>

                {status.open &&
                    <>
                        <Search
                            value={status.value as string}
                            placeholder="Поиск по статусу закупки"
                            onChange={(e) => setStatus({...status, value: e})}
                            onCancel={() => setStatus({...status, value: ""})}
                            withCheckboxes
                            options={announcementStatus}
                            selected={status.selected}
                            setSelected={setStatus}
                            tooltipId="status"
                        />
                        <Tooltip id="status" place="bottom-end" style={{textWrap: "wrap", wordWrap: "break-word", maxWidth: "250px", fontSize: "12px"}}/>
                    </>
                }

                <div className="new-filter-option">
                    {budgetType.selected.length > 0 &&
                        <div className="new-filter-option-name new-filter-option-name-chosen">
                            <div>
                                <span>Выбрано: </span>
                                <span className="new-filter-option-name-current">{budgetType.selected.length > 3 ? `${budgetType.selected.slice(0, 3).join(", ")} и еще(${budgetType.selected.length - 3})` : budgetType.selected.join(", ")}</span>
                            </div>
                            <span onClick={() => setBudgetType({...budgetType, selected: [], selectedIds: []})}>
                                <Cancel />
                            </span>
                        </div>
                    }

                    <div className="new-filter-option-name" onClick={() => {
                        closeAll();
                        setBudgetType({...budgetType, open: !budgetType.open})
                    }}>
                        <span>Вид бюджета</span>
                        <span className={budgetType.open ? "arrow-up" : "arrow"}><ChevronOutline /></span>
                    </div>
                </div>

                {budgetType.open &&
                    <>
                        <Search
                            value={budgetType.value as string}
                            placeholder="Поиск по виду закупки"
                            onChange={(e) => setBudgetType({...budgetType, value: e})}
                            onCancel={() => setBudgetType({...budgetType, value: ""})}
                            withCheckboxes
                            options={budgetTypes}
                            selected={budgetType.selected}
                            setSelected={setBudgetType}
                            tooltipId="budgetType"
                        />
                        <Tooltip id="budgetType" place="bottom-end" style={{textWrap: "wrap", wordWrap: "break-word", maxWidth: "250px", fontSize: "12px"}}/>
                    </>
                }

                <div className="new-filter-option">
                    {type.value.some(item => item.checked) &&
                        <div className="new-filter-option-name new-filter-option-name-chosen">
                            <div>
                                <span>Выбрано: </span>
                                <span className="new-filter-option-name-current">
                                    {type.value.filter(item => item.checked)
                                        .map(item => item.label)
                                        .join(', ')
                                    }
                                </span>
                            </div>
                            <span onClick={() => {
                                const updatedValue = type.value.map(item => ({
                                    ...item,
                                    checked: false,
                                }));

                                setType({
                                    ...type,
                                    value: updatedValue,
                                    ids: [],
                                });
                            }}
                            >
                                <Cancel />
                            </span>
                        </div>
                    }

                    <div className="new-filter-option-name" onClick={() => {
                        closeAll();
                        setType({...type, open: !type.open})
                    }}>
                        <span>Тип закупки</span>
                        <span className={type.open ? "arrow-up" : "arrow"}><ChevronOutline /></span>
                    </div>
                </div>

                {type.open &&
                    <div className="new-filter-option-name-checkbox">
                        {type.value.map((item) =>
                            <div
                                className="new-filter-option-name-checkbox-item"
                                key={item.label}
                            >
                                <input
                                    className="checkbox"
                                    type="checkbox"
                                    id={item.label}
                                    value={item.label}
                                    checked={item.checked}
                                    onChange={() => {
                                        handleCheckboxes(item, setType);
                                    }}
                                />
                                <label
                                    className="checkbox-label"
                                    htmlFor={item.label}
                                    data-tooltip-id="type"
                                    data-tooltip-content={item.label}
                                >
                                    {item.label}
                                </label>
                                <Tooltip id="type" place="bottom-end" style={{textWrap: "wrap", wordWrap: "break-word", maxWidth: "250px", fontSize: "12px"}}/>
                            </div>
                        )}
                    </div>
                }

                <div className="new-filter-option">
                    {tradeMethod.selected.length > 0 &&
                        <div className="new-filter-option-name new-filter-option-name-chosen">
                            <div>
                                <span>Выбрано: </span>
                                <span className="new-filter-option-name-current">{tradeMethod.selected.length > 3 ? `${tradeMethod.selected.slice(0, 3).join(", ")} и еще(${tradeMethod.selected.length - 3})` : tradeMethod.selected.join(", ")}</span>
                            </div>
                            <span onClick={() => setTradeMethod({...tradeMethod, selected: [], selectedIds: []})}>
                                <Cancel />
                            </span>
                        </div>
                    }

                    <div className="new-filter-option-name" onClick={() => {
                        closeAll();
                        setTradeMethod({...tradeMethod, open: !tradeMethod.open})
                    }}>
                        <span>Способ закупки</span>
                        <span className={tradeMethod.open ? "arrow-up" : "arrow"}><ChevronOutline /></span>
                    </div>
                </div>

                {tradeMethod.open &&
                    <>
                        <Search
                            value={tradeMethod.value as string}
                            placeholder="Способ закупки"
                            onChange={(e) => setTradeMethod({...tradeMethod, value: e})}
                            onCancel={() => setTradeMethod({...tradeMethod, value: ""})}
                            withCheckboxes
                            options={tradeMethodFact}
                            selected={tradeMethod.selected}
                            setSelected={setTradeMethod}
                            tooltipId="tradeMethod"
                        />
                        <Tooltip id="tradeMethod" place="bottom-end" style={{textWrap: "wrap", wordWrap: "break-word", maxWidth: "250px", fontSize: "12px"}}/>
                    </>
                }
            </div>
        </NewFilter>
    );
};

export default TendersFilter;