import {
    IAnnounceSummary,
    IModalMethod, IModalStatus,
    IStatusSummary,
    ITenderCustomers,
    ITenderDistrictInfo,
    ITendersList,
    ITenderSuppliers
} from "../types/tenders";
import axiosInstance from "./axiosInstance";

type TResult<DataType> =
    | {
    isError: true;
    error: string;
}
    | {
    isError: false;
    data: DataType;
};

export const getTendersDistrictInfo = async (
    date: string,
    status?: number[],
    budgetType?: number[],
    customer?: number[],
    subject?: number[],
    supplier?: number[],
    tradeMethod?: number[],
    type?: number[],
): Promise<TResult<ITenderDistrictInfo[]>> => {
    try {
        const response = await axiosInstance.get(`/api/tenders/district/list/${date}/`, {
            params: {
                budget_type: budgetType?.length ? budgetType : undefined,
                announcement_status: status?.length ? status : undefined,
                customer: customer?.length ? customer : undefined,
                subject_type: subject?.length ? subject : undefined,
                supplier: supplier?.length ? supplier : undefined,
                trade_method_fact: tradeMethod?.length ? tradeMethod : undefined,
                trade_type: type?.length ? type : undefined,
            },
            paramsSerializer: params => {
                return Object.keys(params)
                    .filter(key => params[key] !== undefined)
                    .map(key => {
                        const value = params[key];
                        if (Array.isArray(value)) {
                            return value.map(v => `${key}=${v}`).join('&');
                        }
                        return `${key}=${value}`;
                    })
                    .join('&');
            }
        });

        return { isError: false, data: response.data };
    } catch (e) {
        return { isError: true, error: (e as Error).message };
    }
};

export const getTendersSuppliers = async (date: string, search?: string, page?: number, perPage?: number): Promise<TResult<ITenderSuppliers>> => {
    try {
        const response = await axiosInstance.get(`/api/tenders/suppliers/${date}/`, {
            params: {
                page: page,
                page_size: perPage,
                search: search,
            },
        });

        return { isError: false, data: response.data };
    } catch (e) {
        return { isError: true, error: (e as Error).message };
    }
};

export const getTendersCustomers = async (date: string, search?: string, page?: number, perPage?: number): Promise<TResult<ITenderCustomers>> => {
    try {
        const response = await axiosInstance.get(`/api/tenders/customers/${date}/`, {
            params: {
                page: page,
                page_size: perPage,
                search: search,
            },
        });

        return { isError: false, data: response.data };
    } catch (e) {
        return { isError: true, error: (e as Error).message };
    }
};

export const getTendersStatusSummary = async (
    date: string,
    district?: string,
    status?: number[],
    budgetType?: number[],
    customer?: number[],
    subject?: number[],
    supplier?: number[],
    tradeMethod?: number[],
    type?: number[],
    ): Promise<TResult<IStatusSummary>> => {
    try {
        const response = await axiosInstance.get(`/api/tenders/status-summary/${date}/`, {
            params: {
                district: district,
                budget_type: budgetType?.length ? budgetType : undefined,
                announcement_status: status?.length ? status : undefined,
                customer: customer?.length ? customer : undefined,
                subject_type: subject?.length ? subject : undefined,
                supplier: supplier?.length ? supplier : undefined,
                trade_method_fact: tradeMethod?.length ? tradeMethod : undefined,
                trade_type: type?.length ? type : undefined,
            },
            paramsSerializer: params => {
                return Object.keys(params)
                    .filter(key => params[key] !== undefined)
                    .map(key => {
                        const value = params[key];
                        if (Array.isArray(value)) {
                            return value.map(v => `${key}=${v}`).join('&');
                        }
                        return `${key}=${value}`;
                    })
                    .join('&');
            }
        });

        return { isError: false, data: response.data };
    } catch (e) {
        return { isError: true, error: (e as Error).message };
    }
};

export const getTendersList = async (
    date: string,
    page: number,
    perPage: number,
    district?: number,
    county?: number,
    status?: number[],
    budgetType?: number[],
    customer?: number[],
    subject?: number[],
    supplier?: number[],
    tradeMethod?: number[],
    type?: number[]
): Promise<TResult<ITendersList>> => {
    try {
        const response = await axiosInstance.get(`/api/tenders/list/${date}/`, {
            params: {
                district: district,
                page: page,
                page_size: perPage,
                county: county,
                budget_type: budgetType?.length ? budgetType : undefined,
                announcement_status: status?.length ? status : undefined,
                customer: customer?.length ? customer : undefined,
                subject_type: subject?.length ? subject : undefined,
                supplier: supplier?.length ? supplier : undefined,
                trade_method_fact: tradeMethod?.length ? tradeMethod : undefined,
                trade_type: type?.length ? type : undefined,
            },
            paramsSerializer: params => {
                return Object.keys(params)
                    .filter(key => params[key] !== undefined)
                    .map(key => {
                        const value = params[key];
                        if (Array.isArray(value)) {
                            return value.map(v => `${key}=${v}`).join('&');
                        }
                        return `${key}=${value}`;
                    })
                    .join('&');
            }
        });

        return { isError: false, data: response.data };
    } catch (e) {
        return { isError: true, error: (e as Error).message };
    }
};

export const getTendersMethods = async (
    date: string,
    district?: string,
    status?: number[],
    budgetType?: number[],
    customer?: number[],
    subject?: number[],
    supplier?: number[],
    tradeMethod?: number[],
    type?: number[]
): Promise<TResult<IModalMethod[]>> => {
    try {
        const response = await axiosInstance.get(`/api/tenders/method/${date}/`, {
            params: {
                district: district,
                budget_type: budgetType?.length ? budgetType : undefined,
                announcement_status: status?.length ? status : undefined,
                customer: customer?.length ? customer : undefined,
                subject_type: subject?.length ? subject : undefined,
                supplier: supplier?.length ? supplier : undefined,
                trade_method_fact: tradeMethod?.length ? tradeMethod : undefined,
                trade_type: type?.length ? type : undefined,
            },
            paramsSerializer: params => {
                return Object.keys(params)
                    .filter(key => params[key] !== undefined)
                    .map(key => {
                        const value = params[key];
                        if (Array.isArray(value)) {
                            return value.map(v => `${key}=${v}`).join('&');
                        }
                        return `${key}=${value}`;
                    })
                    .join('&');
            }
        });

        return { isError: false, data: response.data };
    } catch (e) {
        return { isError: true, error: (e as Error).message };
    }
};

export const getTendersStatus = async (
    date: string,
    method?: number,
    district?: string,
    status?: number[],
    budgetType?: number[],
    customer?: number[],
    subject?: number[],
    supplier?: number[],
    tradeMethod?: number[],
    type?: number[]
    ): Promise<TResult<IModalStatus[]>> => {
    try {
        const response = await axiosInstance.get(`/api/tenders/status/${date}/`, {
            params: {
                trade_method_fact: method,
                district: district,
                budget_type: budgetType?.length ? budgetType : undefined,
                announcement_status: status?.length ? status : undefined,
                customer: customer?.length ? customer : undefined,
                subject_type: subject?.length ? subject : undefined,
                supplier: supplier?.length ? supplier : undefined,
                trade_type: type?.length ? type : undefined,
            },
            paramsSerializer: params => {
                return Object.keys(params)
                    .filter(key => params[key] !== undefined)
                    .map(key => {
                        const value = params[key];
                        if (Array.isArray(value)) {
                            return value.map(v => `${key}=${v}`).join('&');
                        }
                        return `${key}=${value}`;
                    })
                    .join('&');
            }
        });

        return { isError: false, data: response.data };
    } catch (e) {
        return { isError: true, error: (e as Error).message };
    }
};

export const getAnnounceSummary = async (
    date: string,
    district?: string,
    status?: number[],
    budgetType?: number[],
    customer?: number[],
    subject?: number[],
    supplier?: number[],
    tradeMethod?: number[],
    type?: number[]
): Promise<TResult<IAnnounceSummary>> => {
    try {
        const response = await axiosInstance.get(`/api/tenders/announce-summary/${date}/`, {
            params: {
                district: district,
                budget_type: budgetType?.length ? budgetType : undefined,
                announcement_status: status?.length ? status : undefined,
                customer: customer?.length ? customer : undefined,
                subject_type: subject?.length ? subject : undefined,
                supplier: supplier?.length ? supplier : undefined,
                trade_method_fact: tradeMethod?.length ? tradeMethod : undefined,
                trade_type: type?.length ? type : undefined,
            },
            paramsSerializer: params => {
                return Object.keys(params)
                    .filter(key => params[key] !== undefined)
                    .map(key => {
                        const value = params[key];
                        if (Array.isArray(value)) {
                            return value.map(v => `${key}=${v}`).join('&');
                        }
                        return `${key}=${value}`;
                    })
                    .join('&');
            }
        });

        return { isError: false, data: response.data };
    } catch (e) {
        return { isError: true, error: (e as Error).message };
    }
};

export const getTendersCountyInfo = async (
    date: string,
    district: string,
    status?: number[],
    budgetType?: number[],
    customer?: number[],
    subject?: number[],
    supplier?: number[],
    tradeMethod?: number[],
    type?: number[]
): Promise<TResult<ITenderDistrictInfo[]>> => {
    try {
        const response = await axiosInstance.get(`/api/tenders/county/${district}/${date}/`, {
            params: {
                budget_type: budgetType?.length ? budgetType : undefined,
                announcement_status: status?.length ? status : undefined,
                customer: customer?.length ? customer : undefined,
                subject_type: subject?.length ? subject : undefined,
                supplier: supplier?.length ? supplier : undefined,
                trade_method_fact: tradeMethod?.length ? tradeMethod : undefined,
                trade_type: type?.length ? type : undefined,
            },
            paramsSerializer: params => {
                return Object.keys(params)
                    .filter(key => params[key] !== undefined)
                    .map(key => {
                        const value = params[key];
                        if (Array.isArray(value)) {
                            return value.map(v => `${key}=${v}`).join('&');
                        }
                        return `${key}=${value}`;
                    })
                    .join('&');
            }
        });

        return { isError: false, data: response.data };
    } catch (e) {
        return { isError: true, error: (e as Error).message };
    }
};