import React, {useContext, useEffect, useState} from 'react';
import {getTendersMethods, getTendersStatus} from "../../../services/tenders";
import dayjs from "dayjs";
import {IModalMethod, IModalStatus} from "../../../types/tenders";
import {AppDataContext} from "../../../contexts/AppDataContext";
import {chartColors} from "../../../utils/options";
import BarChart from "../../Charts/BarChart";
import Button from "../../UI/Button";
import {useParams} from "react-router-dom";
import {TendersContext} from "../../../contexts/TendersContext";

const TendersPurchaseModal = () => {
    const params = useParams();
    const districtId = params.id;
    const {date} = useContext(AppDataContext);
    const {
        customer,
        supplier,
        subject,
        status,
        budgetType,
        type,
        tradeMethod,
    } = useContext(TendersContext);
    const [statuses, setStatuses] = useState<IModalStatus[]>([]);
    const [methods, setMethods] = useState<IModalMethod[]>([]);

    useEffect(() => {
        loadMethods();
        loadStatuses();
    }, []);

    const loadMethods = async (): Promise<void> => {
        const response = await getTendersMethods(
            dayjs(date).format("YYYY-MM-DD"), districtId, status.selectedIds, budgetType.selectedIds, customer.selectedIds, subject.ids, supplier.selectedIds, tradeMethod.selectedIds, type.ids
        );

        if (!response.isError) {
            setMethods(response.data);
        } else {
            setMethods([]);
        }
    };

    const loadStatuses = async (id?: number): Promise<void> => {
        const response = await getTendersStatus(
            dayjs(date).format("YYYY-MM-DD"), id, districtId, status.selectedIds, budgetType.selectedIds, customer.selectedIds, subject.ids, supplier.selectedIds, tradeMethod.selectedIds, type.ids
        );

        if (!response.isError) {
            setStatuses(response.data);
        } else {
            setStatuses([]);
        }
    };

    const getChartData = (data: IModalStatus[] | IModalMethod[]) => {
        let names: string[] = [];
        let statusesData: number[] = [];
        let statusesColors: string[] = [];

        data.forEach((item, index) => {
            if (item.count) {
                if ("status" in item) {
                    names.push(item.status)
                } else {
                    names.push(item.method)
                }

                statusesData.push(item.count);
                statusesColors.push(chartColors[index]);
            }
        });

        return {
            labels: names,
            datasets: [
                {
                    data: statusesData,
                    backgroundColor: statusesColors,
                    borderColor: statusesColors,
                    borderWidth: 1
                }
            ]
        }
    };

    const handleChartClick = (chart: string) => {
        let methodId = null;
        methods.forEach(method => {
            if (method.method === chart) methodId = method.id;
        });
        if (methodId) loadStatuses(methodId);
    };

    return (
        <>
            <div className="tenders-modal-btn">
                <Button text="Сбросить" onClick={() => loadStatuses()} />
            </div>
            <div>
                <div className="modal-title">Кол-во объявлений по способу закупа</div>
                <div>
                    <BarChart
                        data={getChartData(methods)}
                        isLegend={false}
                        responsive
                        chartWidth={1000}
                        chartHeight={400}
                        onClick={(label: string) => handleChartClick(label)}
                        textRotation={45}
                    />
                </div>
            </div>

            <div>
                <div className="modal-title">Кол-во объявлений по статусу</div>
                <div>
                    <BarChart
                        data={getChartData(statuses)}
                        isLegend={false}
                        responsive
                        chartWidth={1000}
                        chartHeight={400}
                        textRotation={45}
                    />
                </div>
            </div>
        </>
    );
};

export default TendersPurchaseModal;